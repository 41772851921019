import { Component, ViewChild, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  routerHidden = true;
  @ViewChild('splash', {static: false}) splash: ElementRef;

  public appPages = [
    {
      title: 'Acceder a tu cuenta',
      url: '/login',
      icon: 'log-in'
    },
    {
      title: 'Mis Datos',
      url: '/infoper',
      icon: 'person'
    },
    {
      title: 'Lista de Deseos',
      url: '/listadeseos',
      icon: 'heart'
    },
    {
      title: 'Carrito compras',
      url: '/carrito',
      icon: 'cart'
    },
    {
      title: 'Mis compras',
      url: '/compras',
      icon: 'cash'
    },
    {
      title: 'Términos y condiciones',
      url: '/terminos',
      icon: 'checkbox'
    },
    {
      title: 'Contacto',
      url: '/contacto',
      icon: 'file-tray-full'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      setTimeout(() => {
        this.routerHidden = false;
        //this.splash.nativeElement.style.display = 'none';
      }, 4000);

    });
  }
}
