import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'tabs/tab1',
    loadChildren: () =>
      import('./paginas/tab1/tab1.module').then(m => m.Tab1PageModule)
  },
  {
    path: 'tabs/tab2',
    loadChildren: () =>
      import('./paginas/tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'tabs/tab3',
    loadChildren: () =>
      import('./paginas/tab3/tab3.module').then(m => m.Tab3PageModule)
  },
  {
    path: 'listadeseos',
    loadChildren: () => import('./paginas/listadeseos/listadeseos.module').then( m => m.ListadeseosPageModule)
  },
  {
    path: 'carrito',
    loadChildren: () => import('./paginas/carrito/carrito.module').then( m => m.CarritoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./paginas/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'loginreg',
    loadChildren: () => import('./paginas/loginreg/loginreg.module').then( m => m.LoginregPageModule)
  },
  {
    path: 'productdet',
    loadChildren: () => import('./paginas/productdet/productdet.module').then( m => m.ProductdetPageModule)
  },
  {
    path: 'terminos',
    loadChildren: () => import('./paginas/terminos/terminos.module').then( m => m.TerminosPageModule)
  },
  {
    path: 'buscar',
    loadChildren: () => import('./paginas/buscar/buscar.module').then( m => m.BuscarPageModule)
  },
  {
    path: 'compras',
    loadChildren: () => import('./paginas/compras/compras.module').then( m => m.ComprasPageModule)
  },
  {
    path: 'busqdet',
    loadChildren: () => import('./paginas/busqdet/busqdet.module').then( m => m.BusqdetPageModule)
  },
  {
    path: 'productlic',
    loadChildren: () => import('./paginas/productlic/productlic.module').then( m => m.ProductlicPageModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('./paginas/categorias/categorias.module').then( m => m.CategoriasPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./paginas/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'ofertas',
    loadChildren: () => import('./paginas/ofertas/ofertas.module').then( m => m.OfertasPageModule)
  },
  {
    path: 'infoper',
    loadChildren: () => import('./paginas/infoper/infoper.module').then( m => m.InfoperPageModule)
  },
  {
    path: 'productcer',
    loadChildren: () => import('./paginas/productcer/productcer.module').then( m => m.ProductcerPageModule)
  },
  {
    path: 'ron',
    loadChildren: () => import('./paginas/ron/ron.module').then( m => m.RonPageModule)
  },
  {
    path: 'limoncello',
    loadChildren: () => import('./paginas/limoncello/limoncello.module').then( m => m.LimoncelloPageModule)
  },
  {
    path: 'pisco',
    loadChildren: () => import('./paginas/pisco/pisco.module').then( m => m.PiscoPageModule)
  },
  {
    path: 'vermouth',
    loadChildren: () => import('./paginas/vermouth/vermouth.module').then( m => m.VermouthPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
